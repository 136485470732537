import React from "react";
import { PropertyShareSettings } from "./PropertyShareSettings";
import {
  AppraiserProperty,
  PropertyRequestStatus,
} from "../../types/appraiser.types";
import { useMutation, useQueryClient } from "react-query";
import { setLeadContactPermissions, verifyReport } from "../../api/api";
import { showToastError, showToastSuccess } from "common/toast/toast";
import { LinkBox } from "../../components/links/LinkBox";
import { Button, CircularProgress, TextField } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { getErrorMessage } from "common/helpers/error.helpers";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { PropertyAddonToPermission } from "../../types/api.types";
import { usePropertyPurchasedAddons } from "../../hooks/property.hooks";
import { LinkForButton } from "../../components/links/LinkForButton";

interface PropertyLeadShareSettingsProps {
  property: AppraiserProperty;
  onSuccess?: () => void;
}

export const PropertyLeadShareSettings: React.FC<
  PropertyLeadShareSettingsProps
> = ({ property, onSuccess }) => {
  const queryClient = useQueryClient();
  const propertyQueryKey = ["appraiserProperties", property._id];
  const { mutate: mutatePermissions } = useMutation(setLeadContactPermissions, {
    onSuccess: () => {
      showToastSuccess("Nustatymai išsaugoti");
      queryClient.invalidateQueries(propertyQueryKey);
    },
    onError: () => {
      showToastError();
    },
  });

  const { mutate: verify, isLoading } = useMutation(verifyReport, {
    onSuccess: (data) => {
      queryClient.setQueryData(propertyQueryKey, data);
      queryClient.invalidateQueries(propertyQueryKey);
      onSuccess?.();
    },
    onError: (e) => {
      showToastError(getErrorMessage(e));
    },
  });

  const { data: addons, isLoading: isLoadingAddons } =
    usePropertyPurchasedAddons(property._id);

  if (isLoadingAddons) {
    return <LoaderCentered />;
  }

  const purchasedPermissions = addons?.map(
    (addon) => PropertyAddonToPermission[addon.type]
  );

  return (
    <PropertyShareSettings
      initialPermissions={property?.leadContact?.permissions}
      purchasedPermissions={purchasedPermissions}
      onChange={(permissions) => {
        mutatePermissions({ propertyId: property._id, permissions });
      }}
      disablePermissions
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <LinkBox link={property.leadContact?.link} withBorder />
        <Divider />
        <TextField
          disabled
          value={property.leadContact?.email}
          fullWidth
          label="Užsakovo el. paštas"
          style={{ marginTop: "20px" }}
        />
        {property.propertyRequestStatus === PropertyRequestStatus.Completed ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Ataskaita patvirtinta</div>
            <Button
              onClick={() => {
                onSuccess?.();
              }}
              variant="outlined"
            >
              Grįžti
            </Button>
          </div>
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <div style={{ display: "flex", gap: "20px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                verify({ propertyId: property._id, sendEmail: false });
              }}
            >
              Patvirtinti
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                verify({ propertyId: property._id, sendEmail: true });
              }}
            >
              Patvirtinti ir siųsti el. paštu
            </Button>
          </div>
        )}
      </div>
    </PropertyShareSettings>
  );
};

import React from "react";
import { PropertyAddonType } from "../../../types/api.types";
import { getPropertyAddonPrices } from "../../../api/api";
import { useQuery } from "react-query";
import { AddonSelect } from "./AddonSelect";

interface AddonListSelectProps {}

export const addonsLabels = {
  [PropertyAddonType.ComparablesView]: "Panašūs sandoriai",
  [PropertyAddonType.CalculationsView]: "Panašūs sandoriai ir jų skaičiavimai",
  [PropertyAddonType.LocationReport]: "Vietos ataskaita",
  [PropertyAddonType.MarketInsights]: "Rinkos apžvalga",
};

export const AddonListSelect: React.FC<AddonListSelectProps> = () => {
  const { data: addonsPrices } = useQuery(
    "addonsPrices",
    getPropertyAddonPrices
  );

  if (!addonsPrices) {
    return null;
  }

  const addonsToDisclude = [PropertyAddonType.ComparablesView];

  const addonsList = Object.entries(addonsPrices)
    .map(([type, price]) => ({
      type: type as PropertyAddonType,
      label: addonsLabels[type as PropertyAddonType],
      price,
    }))
    .filter((addon) => !addonsToDisclude.includes(addon.type));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        flexWrap: "wrap",
      }}
    >
      {addonsList.map((addon) => (
        <AddonSelect
          key={addon.type}
          addon={addon.type}
          label={addon.label}
          price={addon.price}
        />
      ))}
    </div>
  );
};

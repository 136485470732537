import {
  MyProperty,
  Image,
  LeadContact,
  LeadContactWithPermissions,
  PropertyImage,
  PropertyPhotoCategory,
} from "common/types/common.types";
import { DecodedAppraiserToken, PropertyType } from "./api.types";

export enum AppraiserRole {
  ADMIN = "ADMIN",
  APPRAISER = "APPRAISER",
  ORGANIZATION_LEAD = "ORGANIZATION_LEAD",
}

export interface Appraiser extends BasicAppraiser {
  _id: string;
  invitedAt?: Date;
  acceptedAt?: Date;
  registrationLink?: string;
  resetLink?: string | null;
  decodedRegistrationToken?: DecodedAppraiserToken;
  decodedPasswordResetToken?: DecodedAppraiserToken;
  role: AppraiserRole;
  createdAt: Date;
  updatedAt: Date;
}

export interface BasicAppraiser extends AppraiserProfile {
  email: string;
  organizationName: string;
}

export enum PropertyRequestStatus {
  Created = "created",
  Pending = "pending",
  InProgress = "inProgress",
  Evaluated = "evaluated",
  Completed = "completed",
}

export enum PropertyRequestType {
  Manual = "manual",
  LeadAppraiser = "leadAppraiser",
  LeadOrganization = "leadOrganization",
}
export interface PropertyStatus {
  status: PropertyRequestStatus;
  createdAt: Date;
}

export interface Order {
  _id: string;
  discountCode?: string | null;
}

export interface PhotoCategory {
  category: PropertyPhotoCategory;
  propertyId: string;
  description?: string;
}

export enum PropertyPurpose {
  Pledge = "pledge",
}

export const PropertyPurposeTranslations: Record<PropertyPurpose, string> = {
  [PropertyPurpose.Pledge]: "Įkeitimas",
};

export interface AppraiserProperty extends MyProperty {
  appraiser: Appraiser;
  finalPredictionCreatedAt?: string;
  images: PropertyImage[];
  shareableLink?: string;
  leadContact?: LeadContactWithPermissions;
  propertyStatuses: PropertyStatus[];
  propertyRequestStatus: PropertyRequestStatus;
  propertyRequestType?: PropertyRequestType;
  order?: Order | null;
  simpleType?: PropertyType;
  reportVerifiedAt?: Date;
  expectedValue?: number | null;
  listingUrl?: string;
  photoCategories?: PhotoCategory[];
  purpose?: PropertyPurpose;
}

export interface AppraiserWithLimits {
  _id: string;
  email: string;
  monthlyCreditLimit?: number | null;
  totalCreditsUsed: number;
}

export interface AppraiserProfile {
  profileImageUrl: string;
  fullName: string;
  positionTitle: string;
  phoneNumber: string;
  website?: string;
  certificateNumber?: string;
  certificateValidFrom?: Date | null;
}

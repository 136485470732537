import { Box, Button } from "@mui/material";
import { Divider } from "common/components/listItems/Divider";
import { formatPrice } from "common/helpers/text.helpers";
import colors from "common/styles/colors";
import React from "react";

interface LeadActionCardProps {
  fullPrice: number;
  discountedPrice?: number;
  code?: string | null;
  onOrderClick?: () => void;
}

const details = [
  "NT eksperto individuali analizė",
  "Analizė remiantis panašiais sandoriais",
  "Užklausa įvykdoma per 1-3 d.d.",
];

export const LeadActionCard: React.FC<LeadActionCardProps> = ({
  fullPrice,
  discountedPrice,
  code,
  onOrderClick,
}) => {
  return (
    <Box
      sx={{
        borderColor: colors.lightgrey,
        borderWidth: "1px",
        borderStyle: "solid",
        padding: { xs: "10px", sm: "30px" },
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: "24px",
          fontWeight: 500,
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Sužinok turto kainą
      </div>
      <Divider />
      <div>
        <ul>
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      </div>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px",
          mt: "30px",
          px: { xs: 0, sm: "30px" },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "24px",
              fontWeight: 400,
              display: "flex",
              gap: "10px",
            }}
          >
            <span
              style={{
                textDecoration:
                  discountedPrice !== undefined ? "line-through" : "none",
              }}
            >
              {formatPrice(fullPrice, 2)}
            </span>
            {discountedPrice !== undefined && (
              <span>{formatPrice(discountedPrice, 2)}</span>
            )}
          </div>
          {discountedPrice !== undefined && (
            <span style={{ color: colors.purple2 }}>
              Pritaikytas kodas: {code}
            </span>
          )}
        </div>
        <Button
          variant="contained"
          sx={{
            maxWidth: "345px",
            width: "100%",
          }}
          onClick={onOrderClick}
        >
          Užsakyti
        </Button>
      </Box>
    </Box>
  );
};

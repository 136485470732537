import {
  LeadContact,
  LeadContactWithPermissions,
} from "common/types/common.types";
import React from "react";
import { ContentCard } from "../ContentCard";
import { InfoRow } from "../../routes/propertiesCRUD/InfoRow";
import { formatPrice } from "common/helpers/text.helpers";
import { AddonsSummary } from "../../routes/propertiesCRUD/reportPayments/AddonsSummary";
import { useQuery } from "react-query";
import { getPropertyPurchasedAddons } from "../../api/api";
import { CompositeAsset, PropertyAddonWithPrice } from "../../types/api.types";
import { Box, Button } from "@mui/material";
import { CompositeAssetsSummary } from "../../routes/propertiesCRUD/reportPayments/CompositeAssetsSummary";
import { DesignationType } from "common/types/comparableFields.types";
import { LinkForButton } from "../links/LinkForButton";

interface PropertyLeadContactProps {
  leadContact: LeadContactWithPermissions;
  discountCode?: string | null;
  expectedValue?: number | null;
  addons?: PropertyAddonWithPrice[];
  compositeAssets?: CompositeAsset[];
  hidePdf?: boolean;
}

export const PropertyLeadContact: React.FC<PropertyLeadContactProps> = ({
  leadContact,
  discountCode,
  expectedValue,
  addons,
  compositeAssets,
  hidePdf,
}) => {
  return (
    <>
      <ContentCard title="Užklausos duomenys">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                flex: 1,
              }}
            >
              <InfoRow label="Vardas Pavardė" value={leadContact.name} />
              <InfoRow label="El. pašto adresas" value={leadContact.email} />
              <InfoRow label="Telefono numeris" value={leadContact.phone} />
              <InfoRow label="Nuolaidos kodas" value={discountCode ?? "-"} />
              <InfoRow
                label="Lūkestinė kaina"
                value={!!expectedValue ? formatPrice(expectedValue) : "-"}
              />
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <InfoRow
                label="Komentaras"
                value={leadContact.additionalInfo ?? "-"}
              />
            </div>
          </Box>
          {!!addons && (
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <AddonsSummary
                addons={addons}
                title="Užsakytos papildomos ataskaitos dalys"
                isPurchased={true}
              />
              {!!compositeAssets && (
                <CompositeAssetsSummary
                  assets={compositeAssets.map((asset) => ({
                    designationTypeId: (asset.designationTypeId ??
                      "") as DesignationType,
                    _id: asset._id,
                  }))}
                  title="Užsakytas papildomas priklausomas turtas"
                  isPurchased={true}
                />
              )}
            </div>
          )}
          {!!leadContact.pdfUrl && !hidePdf && (
            <LinkForButton
              to={leadContact.pdfUrl}
              target="_blank"
              style={{ alignSelf: "end" }}
            >
              <Button variant="contained">Atidaryti ataskaitos PDF</Button>
            </LinkForButton>
          )}
        </div>
      </ContentCard>
    </>
  );
};

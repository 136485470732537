import { Divider } from "common/components/listItems/Divider";
import { Typography } from "common/components/Typography";
import {
  ComparableFull,
  FinalPricePredictions,
} from "common/types/common.types";
import React, { useContext } from "react";
import { AppraiserProperty } from "../types/appraiser.types";
import { AdjustmentsMap } from "./map/AdjustmentsMap";
import { MapListInteractionsContext } from "./MapListInteractionsProvider";
import { ComparableCard } from "./map/ComparableCard";
import { Box } from "@mui/material";

interface PredictionOverviewComparablesProps {
  editButton?: React.ReactNode;
  comparables: ComparableFull[];
  property: AppraiserProperty;
  hideAdjustments?: boolean;
  isPrintable?: boolean;
}

export const PredictionOverviewComparables: React.FC<
  PredictionOverviewComparablesProps
> = ({ editButton, comparables, property, hideAdjustments, isPrintable }) => {
  const { onListComparableClick } = useContext(MapListInteractionsContext);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          height: "60px",
        }}
      >
        <Typography>Atrinkti sandoriai kainos skaičiavimui</Typography>
        {editButton}
      </div>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          marginTop: "30px",
          height: { xs: undefined, md: isPrintable ? undefined : "525px" },
          gap: "24px",
        }}
      >
        <div
          style={{
            overflowY: "scroll",
          }}
        >
          {comparables.map((comp, index) => (
            <div
              key={comp.comparable_transaction.id}
              style={{ marginBottom: "15px" }}
            >
              <ComparableCard
                comparable={comp}
                adjustments={hideAdjustments ? undefined : comp.adjustments}
                onClick={() => onListComparableClick?.(comp)}
                withNumber={index + 1}
                isOverview
              />
            </div>
          ))}
        </div>
        <Box
          sx={{
            flex: { xs: undefined, md: 1 },
            height: { xs: "300px", md: "auto" },
          }}
        >
          <AdjustmentsMap
            property={property}
            comparables={comparables}
            mapOptions={{
              mapTypeControl: false,
              streetViewControl: false,
              scrollwheel: undefined,
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

import { StyledImage } from "common/components/images/StyledImage";
import React from "react";
import { BasicAppraiser } from "./BasicAppraiser";
import { ContentCard } from "../../components/ContentCard";
import { PropertyTypeOption } from "./PropertyTypeOption";
import { PropertyType } from "../../types/api.types";
import { useQuery } from "react-query";
import { getBasicAppraiser, getReportPrices } from "../../api/api";
import { LeadActionCard } from "./LeadActionCard";
import { PropertyCreationContext } from "./PropertyCreationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { getDesignationTypeFromSimpleType } from "../../helpers/leadProperty.helpers";
import { useRouteQuery, useUpdateQueryParam } from "../../hooks/router.hooks";
import { Box, Button, Link, Tooltip } from "@mui/material";
import { LoaderCentered } from "../../components/loaders/LoaderCentered";
import { BasicOrganization } from "./BasicOrganization";
import { AddonListSelect } from "../property/addons/AddonListSelect";
import { LeadLandingPageActionItem } from "./LeadLandingPageActionItem";
import { LinkForButton } from "../../components/links/LinkForButton";
import { Typography } from "common/components/Typography";
import { OrderedListItem } from "../../components/listItems/OrderedListItem";
import colors from "common/styles/colors";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

interface LeadLandingPageProps {}

const valuationServices = [
  {
    label: "Buto vertinimas",
    value: PropertyType.Apartment,
  },
  {
    label: "Namo vertinimas",
    value: PropertyType.House,
  },
  {
    label: "Kotedžo vertinimas",
    value: PropertyType.Cottage,
  },
];

const howItWorks = [
  {
    title: "Įveskite turto duomenis",
    description:
      "Užpildykite užklausos formą apie norimą įvertinti turtą ir jei reikia – nurodykite Jūsų lūkestį turto vertei.",
  },
  {
    title: "Sekite užsakymo eigą",
    description:
      "El. paštu gausite nuorodą, kurioje galėsite stebėti užsakymo eigą ir užsisakyti papildomas paslaugas.",
  },
  {
    title: "Turto vertinimo procesas",
    description:
      "Vertintojas atliks turto vertinimą naudodamas kredito įstaigų reikalaujamą palyginamąjį metodą. Bus atrinkti panašūs sandoriai ir apskaičiuotas kvadratinio metro kainos vidurkis.\n\nJei preliminari kaina Jums tinka, galite užsisakyti išsamų turto vertinimą su papildoma turto apžiūra.",
  },
];

export const LeadLandingPage: React.FC<LeadLandingPageProps> = () => {
  const [selectedPropertyType, setSelectedPropertyType] =
    React.useState<PropertyType>(PropertyType.Apartment);

  const appraiserId = useRouteQuery().get("appraiserId");
  const discountCode = useRouteQuery().get("discountCode");

  const { selectedAddons } = React.useContext(PropertyCreationContext);

  const { data: reportPrices, isLoading: isLoadingPrices } = useQuery(
    [
      "report_prices",
      appraiserId,
      discountCode,
      JSON.stringify(selectedAddons),
    ],
    () =>
      getReportPrices(
        appraiserId ?? "",
        discountCode ?? undefined,
        selectedAddons
      ),
    {
      enabled: !!appraiserId,
      keepPreviousData: true,
    }
  );

  const navigate = useNavigate();

  const { getUpdatedQueryParam } = useUpdateQueryParam();

  const { data: basicData, isLoading } = useQuery(
    ["basic_appraiser", appraiserId],
    () => getBasicAppraiser(appraiserId ?? ""),
    {
      enabled: !!appraiserId,
    }
  );

  const { appraiser, organization } = basicData ?? {};

  if (isLoading || isLoadingPrices) {
    return <LoaderCentered />;
  }

  if ((!appraiser && !organization) || !reportPrices) {
    return <div>Klaida</div>;
  }

  const prices = reportPrices?.data[selectedPropertyType];

  return (
    <div>
      {!!appraiser && (
        <BasicAppraiser appraiser={appraiser} organization={organization} />
      )}
      {!!organization && !appraiser && (
        <BasicOrganization organization={organization} />
      )}

      <ContentCard title="Preliminarios turto kainos ataskaita">
        <div>
          {
            "Mūsų patyrę nekilnojamojo turto vertintojai atliks Jūsų turto rinkos analizę ir pateiks Jums preliminarų turto kainos nustatymą. Tai greitas ir ekonomiškas būdas sužinoti apytikslę Jūsų turto kainą, pagrįstą rinkos analize ir turto charakteristikomis."
          }
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          {"Peržiūrėti, kaip atrodys turto kainos nustatymo ataskaita:"}
          <LinkForButton
            target="_blank"
            to="https://www.relo.lt/relo-turto-ataskaita"
          >
            <Button>pavyzdinė ataskaitą</Button>
          </LinkForButton>
        </div>
        <Typography variant="h3">Kaip tai veikia?</Typography>
        <div
          style={{ maxWidth: "600px", margin: "0 auto", paddingTop: "30px" }}
        >
          {howItWorks.map((step, index) => (
            <OrderedListItem
              key={index}
              title={step.title}
              description={step.description}
              index={index + 1}
              isLast={index === howItWorks.length - 1}
            />
          ))}
        </div>
      </ContentCard>
      <ContentCard title="Paslaugos užsakymas">
        <LeadLandingPageActionItem index={1} title="Pasirinkite turto tipą">
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginBottom: "30px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {valuationServices.map((service) => (
              <PropertyTypeOption
                key={service.value}
                checked={selectedPropertyType === service.value}
                label={service.label}
                onClick={() => setSelectedPropertyType?.(service.value)}
              />
            ))}
          </Box>
        </LeadLandingPageActionItem>
        <LeadLandingPageActionItem
          index={2}
          title="Papildomos ataskaitos dalys"
        >
          <AddonListSelect />
        </LeadLandingPageActionItem>
        <div style={{ marginTop: "40px" }} />
        <LeadActionCard
          fullPrice={prices.fullPrice}
          discountedPrice={prices.discount ? prices.totalPrice : undefined}
          code={discountCode}
          onOrderClick={() => {
            const updateLocation = getUpdatedQueryParam([
              {
                paramName: "type",
                newValue:
                  getDesignationTypeFromSimpleType(selectedPropertyType),
              },
              { paramName: "simpleType", newValue: selectedPropertyType },
            ]);

            navigate({
              pathname: "../inputMethod",
              search: updateLocation.search,
            });
          }}
        />
        <div
          style={{
            borderColor: colors.greybg,
            borderWidth: "1px",
            borderRadius: "10px",
            borderStyle: "solid",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <OrderedListItem
            index={0}
            isLast
            customIcon={<CampaignOutlinedIcon />}
            title="Pilna turto vertinimo ataskaita nuo 220 EUR!"
            description="Pasiūlymas taikomas po preliminarios turto vertinimo ataskaitos gavimo, kurios kaina bus įtraukta į galutinę sumą. Tiksli kaina priklauso nuo Jūsų turto vietos ir tipo."
          />
        </div>
      </ContentCard>
    </div>
  );
};
